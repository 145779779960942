.carousel-images{
    display: flex;
    justify-content: center;
}
.carousel-images img{
    width: 15rem;
    padding:0 1rem;

}
.carousel2-images{
    display: flex;
    justify-content: center;
}
.carousel2-images img{
    width: 70vw;

}
@media screen and (max-width:500px) {
    .carousel-images{
        display: block;
    }
    .carousel-images img{
        width: 22rem;
        padding: 0.5rem;
    }
    .carousel2-images img{
        width: 115vw;
        scale: 1.1;
    }
}

.iconRotate {
    animation: iconRotator 1s linear 0s 1;
}

@keyframes iconRotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.zoom {
    transition: all 0.3s linear;
}

.zoom:hover {
    transform: scale(1.01);
    border: 1px solid rgb(205, 205, 226);
    background-color: rgba(2, 255, 255, 0.1);
}


.tagLine {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

#madeByMohit {
    color: #5e82f4;
    font-weight: 500;
    width: 0ch;
    overflow: hidden;
    white-space: nowrap;
    animation: tagline 10s  infinite alternate;
    border-right: 3px solid #5e82f4; 
}

@keyframes tagline {
      0% {
          width: 0ch;
      }
      50% {
          width: 28ch;
      }
}



html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  /* height: 80vh; */
}



.calendar {
  display: flex;
  height: 100vh;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  /* background-size: 90vw 100vh; */
  background-position: center; 
  cursor: cell;
  flex-direction: column;
  position: relative;

}
/* .fullcalendarContainer{
    background: url("../assets/petek.jpg");
    background-size: cover;


} */
.loading-bee{
  position: absolute;
  top: 50%;
  left:40%;
  transform: translate(40%,-50%);
  z-index: 5;
}

.calendar-main {
  flex-grow: 1;  
}
.calendar-main h1{
  background-color: rgb(255, 255, 255);
  transform: translateY(-30%);
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  width: 100%;

}

.fc { /* the calendar root */
  max-width: 1200px;
  height: 90vh;
  margin: 0rem auto;
  border: 1px double rgb(35, 55, 6);
  padding: 0.7rem;
  background-color: rgb(255, 255, 255);
  opacity: 0.90;
    /* transform: translateY(-2%); */
}
.fc > *{
  font-weight: 900;

}

.fc-highlight {
  background: rgba(69, 67, 67, 0.431);
  
}
.fc-day a{
  color: black;
}
.fc-day:hover{
  background-color: rgba(69, 67, 67, 0.197);
}

.fc-day-today {
    background: rgb(205, 212, 151) ;
    border-bottom: 2px solid black ;
  
} 


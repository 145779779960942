

.sidebar{
    position: fixed;
    left: 0;
    top:0;
    z-index: 1;
    
  }
  #header{
  overflow: hidden;
  height: 200vh;
}
#header .pro-sidebar{
  height: 200vh;
  width: 4rem;
  transition: width 0.5s;

}
#header .pro-sidebar-lg{
  height: 200vh;
  width: 15rem;
  transition: width 0.5s;
  visibility: visible;


}
#header .pro-sidebar-none{
  height: 200vh;
  width: 4rem;
  visibility: hidden;
  display: none;

}


.menu-handler-container{
  background-color:rgba(29,29,29, 09) ;
  padding: 0.7rem;
  min-width: 4rem;
  height: 3.5rem;
  position: fixed;
  left: 0%;
  top:10rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 2rem 2rem 0;
  color: white;
  cursor: pointer;



}
.menu-handler-container-active{
  background-color:rgb(29,29,29) ;
  width: 5rem;
  height: 3.5rem;
  position: fixed;
  left: 0%;
  top:5rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}
.menu-handler-container-active > *{
  cursor: pointer;

}
.menu-handler-container:hover{
  background-color:rgb(29,29,29) ;
  min-width: 4.5rem;
  transition: min-width  0.5s;
}
.hamburger-menu{
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
   

}
.line{
    width: 30px;
    height: 3px;
    background-color: #d8d8d8;
    transition: 0.8s;
}
.change .line1{
    transform: rotateZ(-405deg) translate(5px, -5px);
}
.change .line2{
  transform: rotateZ(405deg) translate(3px, 3px);
}
.change .line3{
    transform: rotateZ(405deg) translate(20px ,-50px);    
}
.change .line4{
    transform: rotateZ(-405deg) translate(43px ,27px);    
}


.nav-link{
    color: #F5DEB3;
    letter-spacing: 1px;
    position: relative;
    padding: 0px 0px;
    cursor: pointer;

}
.nav-link:hover{
    color: #F5DEB3;
    background-color: rgba(255, 255, 255, 0.1);


}



.nav-link::after,
.nav-link::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #F5DEB3;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.5s;
}
.nav-link::after{
    bottom: 0;
    transform-origin: left;
    color: #F5DEB3;

}
.nav-link::before{
    top: 0;
    transform-origin: right;
    color: #F5DEB3;

}
.nav-link:hover::after,
.nav-link:hover::before{
    transform: scaleX(1);
}
.logo{
   width: 5rem;
}


#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #ff6f00;
  transition: background-color 1s ease-in-out;
}
.none{
  display:none;
}





















/* #header {
    height: 100%;
    overflow-y: hidden;

  }

  #header .closemenu {
    color: #d8d8d8;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 15px;
    cursor: pointer;
  }
  header .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  } */
/*   
  #header .pro-sidebar-inner {
    background-color: #1d1d1d;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #d8d8d8;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #d8d8d8;
    margin: 10px 0px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #2b2b2b;
    color: #d8d8d8;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #d8d8d8;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #1a1917 0%, #221b02 100%);
  }
  #header .logo {
    padding: 20px;
  }
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  } */